@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200&display=swap);
body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.header{
  /* background: #292d3e; */
  /* background: #001529; */
  color: #f1f1f3
}
.content{
  background: #f1f1f3;
  color:#00cde7
}
.footer{
  background: #292d3e;
  color:#00cde7;
  text-align: "center"
}

/*
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

/* .bg {
    background: hsla(210, 2%, 19%, 1);
    background: linear-gradient(135deg, hsla(210, 2%, 19%, 1) 0%, hsla(280, 4%, 48%, 1) 100%);
    background: -moz-linear-gradient(135deg, hsla(210, 2%, 19%, 1) 0%, hsla(280, 4%, 48%, 1) 100%);
    background: -webkit-linear-gradient(135deg, hsla(210, 2%, 19%, 1) 0%, hsla(280, 4%, 48%, 1) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#303132", endColorstr="#7D7780", GradientType=1 );
} */

/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100&display=swap'); */
body{
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
}

.icon-color{
  color: #00cde7; 
}

a{
    color: #00cde7; 
}

a:hover{
    color: #00DBF6;
     
}

html {
    scroll-behavior: smooth;
}

hr {
	height: 4px;
	width: 70px;
	text-align: center;
	position: relative;
	background: #00cde7;
	margin: 0 auto;
	margin-bottom: 30px;
	border: 0;
}



.dark{
    background: #222;
}

.text-center {
    text-align: center;
}

.section-title.center {
	padding: 30px 0;
}
.section-title h2, .section-title.center h2 {
	margin-bottom: 30px;
}


.flex-container-main {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: normal;
  align-content: stretch;
}

.flex-items-main:nth-child(1) {
  display: block;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  order: 0;
}

.flex-items-main:nth-child(2) {
  display: block;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: stretch;
  order: 0;
}

.flex-items-main:nth-child(3) {
  display: block;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  order: 0;
}



.homepage-header-wrapper {
    /* background: radial-gradient(89.41% 108.15% at 33.63% 56.48%,#49432c 0,#402812 54.17%,#000 100%); */
    background: hsla(210, 2%, 19%, 1);
    background: linear-gradient(135deg, hsla(210, 2%, 19%, 1) 0%, hsla(280, 4%, 48%, 1) 100%);
    background: -webkit-linear-gradient(135deg, hsla(210, 2%, 19%, 1) 0%, hsla(280, 4%, 48%, 1) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#303132", endColorstr="#7D7780", GradientType=1 );
    height: 85vh;
    overflow: hidden;
    position: relative;
}

.homepage-header-wrapper video {
    height: 100vh;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100vw;
    z-index: 0;
}

.homepage-header-content {
    color: #fff;
    height: 100%;
    margin: 0 auto;
    max-width: 100%;
    position: absolute;
    text-align: center;
    top: 0;
    width: 100%;
}

.flex-container-outer {

    display: flow-root;
    justify-content: space-between; 
    align-items: baseline; 
    /* flex-flow: row nowrap; */ 
    flex-direction: row; 
    flex-wrap: wrap; 
    align-content: stretch;

    /* background-color: #bbdefb; */
    /* height: 100%; */
    padding: 15px;
    grid-gap: 5px;
    gap: 5px;
  }

  .flex-container-outer > div{
    /* background: #ffecb3; */
    border: 0px solid red;
    border-radius: 5px;
    padding: 4px;
  }

.flex-container {
    display: flex;
    justify-content: flex-end; 
    align-items: center; 
    /* flex-flow: row nowrap; */ 
    flex-direction: row; 
    flex-wrap: wrap; 
    align-content: stretch;

    /* background-color: #bbdefb; */
    height: 100%;
    padding: 15px;
    grid-gap: 5px;
    gap: 5px;
    border: 0px solid green;

  }

  .flex-container > div{
    /* border: 0px solid #ffcc80; */
    padding: 4px;
    z-index: 1000;
    font-weight:500;
    font-size:0.9rem;
    margin-left: 12px;
  }

.logo-div{
    /* height: 40px; */
    z-index: 1000;
    /* top: 30px;
    left: 30px; */
    float: left;
    position: absolute;
}

.center {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color:#fff;
    font-weight: bold;
    font-size: 2.0rem;
  }

  
.center-title {
    margin: 0;
    position: relative;
    top: -4px;
    left: 14px;
    color:#000;
    font-weight: bold;
    font-size: 16px;
    /* border:1px solid red */
    /* display: 'inline-block' */
  }

  .page-icon{
    font-size: 24px; 
    color: #00cde7; 
    margin: 'auto'; 
    /* border:1px solid red;  */
    vertical-align:middle
  }
  

  /* div.b {
    white-space: nowrap; 
    width: 50px; 
    overflow: hidden;
    text-overflow: ellipsis; 
    border: 1px solid #000000;
  } */

/* .container span {
     height: 40px; /* image's height /
     display: table-cell;
     vertical-align: middle;
} */

.ant-btn-primary {
    background-color: #00cde7;
    border:none;
    color:#ffffff;
}

.ant-btn-primary:hover {
    background-color: #00DBF6;
    border:none;
    color:#fff;
}

.flex-container-footer {

    display: flex;
    
    justify-content: space-between; 
    align-items: stretch; 
    /* flex-flow: row wrap; */ 
    flex-direction: row; 
    flex-wrap: wrap; 
    align-content: center;

    background-color: #555555;
    
    height: 100%;
    padding: 4px;
    grid-gap: 5px;
    gap: 5px;

  }

  .flex-container-footer > div{
    background: #555555;
    color:#f1f1f3;
    /* border: 1px solid red; */
    padding: 8px;
  }


  .item { 
    flex-grow:0;
    width:120px;
  }

  /* .item-spacer { 
    flex-grow:0;
  } */

  .item-copy { 
    flex-grow:1;
    text-align:right;
    width:auto;
  }


  .fade-enter {
    opacity: 0;
    animation-duration: .5s;
    animation-fill-mode: both;
    animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
    animation-play-state: paused;
  }
  
  .fade-appear {
    opacity: 0;
    animation-duration: .5s;
    animation-fill-mode: both;
    animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
    animation-play-state: paused;
  }
  
  .fade-leave {
    animation-duration: .5s;
    animation-fill-mode: both;
    animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
    animation-play-state: paused;
  }
  
  .fade-enter.fade-enter-active {
    animation-name: fadeIn;
    animation-play-state: running;
  }
  
  .fade-appear.fade-appear-active {
    animation-name: fadeIn;
    animation-play-state: running;
  }
  
  .fade-leave.fade-leave-active {
    animation-name: fadeOut;
    animation-play-state: running;
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

@media screen and (max-width: 3840px){
    .logo-img{
        height:40px;
    }    
    .flex-container > div{
        font-size: 0.9rem;
        margin-left: 12px;
    }
}

@media screen and (max-width: 800px){
    .logo-img{
        height:24px;
        padding:2px;
    }
    .flex-container > div{
        font-size: 0.8rem;
        margin-left: 0px;
    }
}

