
.header{
  /* background: #292d3e; */
  /* background: #001529; */
  color: #f1f1f3
}
.content{
  background: #f1f1f3;
  color:#00cde7
}
.footer{
  background: #292d3e;
  color:#00cde7;
  text-align: "center"
}

/*
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
